<template>
  <div class="village-info-container">
    <div class="village-info-top">
      <div class="case-all-top-title">
        {{ t('HR001.queryConditionTitle') }}
      </div>
      <div class="case-all-top-group">
        <a-select
          ref="select"
          v-model:value="selectedVillage"
          style="width: 220px"
          :options="villageOptions"
          :placeholder="placeHolderChooseVillage"
        ></a-select>
      </div>
      <div class="case-all-top-date">
        <a-range-picker
          style="width: 220px"
          :ranges="ranges"
          show-time
          v-model:value="fromToDatelist"
          format="YYYY/MM/DD"
          @change="onChangeDate"
        />
      </div>
      <div class="case-all-top-confirm">
        <a-button
          class="confirm-button"
          @click="onClickQueryBtn"
          shape="round"
          :disabled="isQueryBtnDisable"
        >
          {{ t('HR001.query') }}
        </a-button>
      </div>
      <div class="case-all-top-back-dashboard">
        <a-button class="confirm-button" @click="clickBackDashboard">
          <template #icon><ArrowLeftOutlined /></template>
          回儀錶板
        </a-button>
      </div>
      <!-- <div class="case-all-top-export">
        <a-button
          class="export-button"
          @click="clickExport"
          :disabled="!isExportON"
          :loading="isExportLoading"
        >
          <template #icon><ExportOutlined /></template>
          {{ t('HR001.export') }}
        </a-button>
      </div> -->
    </div>
    <div class="village-info-detail">
      <a-row
        type="flex"
        justify="center"
        style="padding-top: 18px; padding-bottom: 40px"
      >
        <a-col flex="center">
          <a-radio-group
            v-model:value="villageDisplayMode"
            class="switch-mode-control"
            button-style="solid"
          >
            <a-radio-button class="mode-btn-shadow" value="villageListMode">
              清單
            </a-radio-button>
            <a-radio-button value="villageCardMode">看板</a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
      <template v-if="villageDisplayMode === 'villageListMode'">
        <VillageList
          :statistical-data="statisticalData"
          @clickItem="clickItem"
        ></VillageList>
      </template>
      <template v-if="villageDisplayMode === 'villageCardMode'">
        <VillageCardList
          :statistical-data="statisticalData"
          @clickItem="clickItem"
        ></VillageCardList>
      </template>
    </div>
  </div>
</template>
<script setup>
  import dayjs from 'dayjs'
  import moment from 'moment'
  import { useRoute, useRouter } from 'vue-router'
  import { ExportOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue'
  import { useStore } from 'vuex'
  import { ref, computed, onMounted, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import VillageCardList from '@/views/villageInfo/components/VillageCardList.vue'
  import VillageList from '@/views/villageInfo/components/VillageList.vue'
  import { getDealerDistrictExport } from '@/api/statistics'
  import {
    getClientDistrictAbnormal,
    getClientCountryDistrictAbnormal,
  } from '@/api/iframeDashboard.js'
  import Cookies from 'js-cookie'

  const { t } = useI18n()
  const route = useRoute()
  const router = useRouter()

  const ranges = {}
  ranges[t('DatePicker.today')] = [moment(), moment()]
  ranges[t('DatePicker.yesterday')] = [
    moment().add(-1, 'days'),
    moment().add(-1, 'days'),
  ]
  ranges[t('DatePicker.thisMonth')] = [
    moment().startOf('month'),
    moment().endOf('month'),
  ]
  ranges[t('DatePicker.latestMonth')] = [moment().add(-1, 'months'), moment()]
  ranges[t('DatePicker.latestThreeMonths')] = [
    moment().add(-3, 'months'),
    moment(),
  ]
  ranges[t('DatePicker.latestYear')] = [moment().add(-1, 'years'), moment()]

  const placeHolderChooseVillage = t('DealerDashboard.chooseVillage')
  const fromToDatelist = ref([])
  const fromDate = ref(0)
  const toDate = ref(0)

  const selectedVillage = ref('所有行政區')
  let selectedVillageConfirm = '所有行政區'

  const villageOptions = ref([{ value: '所有行政區', label: '所有行政區' }])

  const isExportLoading = ref(false)

  const villageDisplayMode = ref('villageListMode')

  const store = useStore()

  const dealerDistrictAbnormal = ref([])
  const dealerClientAbnormal = ref([])

  const dealerClient = ref([])
  const dealerQueryTimeCondition = computed(
    () => store.state.statistics.dealerQueryTimeCondition
  )

  const statisticalData = ref([])

  const isQueryBtnDisable = computed(() => {
    let isQueryBtnDisable = true
    if (fromDate.value > 0 && toDate.value > 0) {
      isQueryBtnDisable = false
    }

    return isQueryBtnDisable
  })

  const isExportON = computed(() => {
    if (
      fromDate.value > 0 &&
      toDate.value > 0 &&
      selectedVillage.value &&
      selectedVillage.value != '所有行政區'
    ) {
      return true
    }
    return false
  })

  const getDealerClient = async () => {
    try {
      const apiResp = await getClientDistrictAbnormal(
        'pEU7ZvTuXw5oUKsRBzo6iZo43DoXs3t6',
        {
          startTs: fromDate.value,
          endTs: toDate.value,
        }
      )

      if (apiResp?.ok && apiResp?.ok === true) {
        dealerClient.value = apiResp.result
      } else {
        dealerClient.value = []
      }
    } catch (error) {
      dealerClient.value = []
    }
  }

  const getDealerDistrictAbnormal = async () => {
    try {
      const apiResp = await getClientDistrictAbnormal('', {
        startTs: fromDate.value,
        endTs: toDate.value,
      })

      if (apiResp?.ok && apiResp?.ok === true) {
        dealerDistrictAbnormal.value = apiResp.result
      } else {
        dealerDistrictAbnormal.value = []
      }
    } catch (error) {
      dealerDistrictAbnormal.value = []
    }
  }

  const getDealerClientAbnormal = async (postBody) => {
    try {
      const apiResp = await getClientCountryDistrictAbnormal('', postBody)

      if (apiResp?.ok && apiResp?.ok === true) {
        dealerClientAbnormal.value = apiResp.result
      } else {
        dealerClientAbnormal.value = []
      }
    } catch (error) {
      dealerClientAbnormal.value = []
    }
  }

  onMounted(async () => {
    if (
      dealerQueryTimeCondition.value &&
      dealerQueryTimeCondition.value.startTs > 0 &&
      dealerQueryTimeCondition.value.endTs > 0
    ) {
      fromToDatelist.value.push(moment(dealerQueryTimeCondition.value.startTs))
      fromToDatelist.value.push(moment(dealerQueryTimeCondition.value.endTs))
      fromDate.value = dealerQueryTimeCondition.value.startTs
      toDate.value = dealerQueryTimeCondition.value.endTs
    } else {
      const today = moment()
      const threeMonthAgo = moment().add('M', '-3')
      fromDate.value = threeMonthAgo.unix() * 1000
      toDate.value = today.unix() * 1000
      fromToDatelist.value = []
      fromToDatelist.value.push(threeMonthAgo)
      fromToDatelist.value.push(today)
    }

    await getDealerClient()
    selectedVillage.value = route.query.district
      ? route.query.district
      : '所有行政區'
    selectedVillageConfirm = selectedVillage.value
    onClickQueryBtn()
  })

  // const updateGroupOptions = () => {
  //   groupOptions.value = []
  //   if (!groups.value) {
  //     return
  //   }
  //   groups.value.forEach((item) => {
  //     groupOptions.value.push({
  //       value: item.groupId.toString(),
  //       label: item.title,
  //     })
  //   })
  // }

  const onChangeDate = (date, dateString) => {
    if (dateString && dateString.length == 2) {
      const newFromDate = dayjs(dateString[0]).startOf('day').unix()
      const newToDate = dayjs(dateString[1]).endOf('day').unix()
      fromDate.value = newFromDate * 1000
      toDate.value = newToDate * 1000
      // console.log(fromDate)
      // console.log(toDate)
    }
  }

  const onClickQueryBtn = () => {
    if (fromDate.value <= 0 || toDate.value <= 0) {
      return
    }

    selectedVillageConfirm = selectedVillage.value
    if (selectedVillage.value === '所有行政區') {
      getDealerDistrictAbnormal({
        startTs: fromDate.value,
        endTs: toDate.value,
      })
      // console.log(`select: ${selectedVillage.value}`)
    } else {
      getDealerClientAbnormal({
        city: '南投',
        district: selectedVillage.value,
        startTs: fromDate.value,
        endTs: toDate.value,
      })
      // console.log(`select: ${selectedVillage.value}`)
    }
  }

  const clickItem = (village) => {
    if (selectedVillageConfirm === '所有行政區') {
      return
    }

    const found = dealerClientAbnormal.value.find((element) => {
      return (
        element.district === selectedVillageConfirm && element.title === village
      )
    })

    console.log(selectedVillageConfirm)
    console.log(village)
    console.log(found)
    // console.log(top.window.location.origin)
    // const goToUrl =
    //   top.window.location.origin +
    //   '/#/monitor/list?partner_id=6215&eid=' +
    //   found.id
    // console.log(goToUrl)

    const callback = Cookies.get('iframe-callback')
    console.log(found.id)
    console.log(callback)
    if (found.id && callback) {
      const goToUrl = callback + found.id
      console.log(goToUrl)
      top.window.location = goToUrl
      // router.push(goToUrl2)
    }
  }

  const clickExport = async () => {
    isExportLoading.value = true
    try {
      if (fromDate.value == 0 && toDate.value == 0) {
        return
      }

      const params = {
        city: '新北市',
        district: selectedVillage.value,
        lang: 'tw',
        startTs: fromDate.value,
        endTs: toDate.value,
      }
      // if (this.selectedGroup.length > 0) {
      //   params.groups = selectedGroup.join(',')
      // }
      const respData = await getDealerDistrictExport(params)
      // console.log(respData)
      // console.log(respData.headers['Content-Disposition'])
      // console.log(respData.headers['content-disposition'])
      let fileName = 'Report.xlsx'
      let contentDisposition = respData.headers['content-disposition']
      if (!contentDisposition) {
        contentDisposition = respData.headers['Content-Disposition']
      }
      if (contentDisposition) {
        fileName = contentDisposition.split(' ')[1].replace('filename=', '')
      }
      const url = window.URL.createObjectURL(new Blob([respData.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      isExportLoading.value = false
    } catch (e) {
      isExportLoading.value = false
      console.log(e)
    }
  }

  const clickBackDashboard = () => {
    router.push({
      name: 'iframeDashboard',
    })
  }

  watch(dealerDistrictAbnormal, (current, prev) => {
    if (selectedVillageConfirm === '所有行政區') {
      statisticalData.value = []
      current.forEach((element) => {
        const newData = { ...element }
        newData.title = newData.districtTownship
        statisticalData.value.push(newData)
      })
    }
    // else {
    //   statisticalData.value = [...Array(29)].map((_, i) => {
    //     return {
    //       key: i,
    //       district: `財團法人伊甸社會福利基金會......三峽身心障礙福利中心 ${
    //         i + 1
    //       }`,
    //       TemperatureTotal: Math.floor(Math.random() * 1000),
    //       TemperatureAbnormal: Math.floor(Math.random() * 10),
    //       BodyWeightTotal: Math.floor(Math.random() * 1000),
    //       BodyWeightAbnormal: Math.floor(Math.random() * 10),
    //       BloodPressureTotal: Math.floor(Math.random() * 1000),
    //       BloodPressureAbnormal: Math.floor(Math.random() * 10),
    //     }
    //   })
    // }
  })

  watch(dealerClientAbnormal, (current, prev) => {
    if (selectedVillageConfirm !== '所有行政區') {
      statisticalData.value = []
      current.forEach((element) => {
        statisticalData.value.push(element)
      })
    }
  })

  watch(dealerClient, (current, prev) => {
    villageOptions.value = [{ value: '所有行政區', label: '所有行政區' }]
    const districts = [...new Set(current.map((item) => item.districtTownship))]
    districts.forEach((name) => {
      villageOptions.value.push({
        value: name,
        label: name,
      })
    })
  })
</script>
<style lang="less" scoped>
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background-color: #4ebaa8;
    border-color: #4ebaa8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #fff;
    background: #fff;
    // border-color: red;
    // -webkit-box-shadow: -1px 0 0 0 red;
    // box-shadow: -1px 0 0 0 red;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }

  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #4dbaa7;
    // background: #fff;
    // border-color: red;
    // -webkit-box-shadow: -1px 0 0 0 red;
    // box-shadow: -1px 0 0 0 red;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 2px !important;
  }

  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .village-info-container {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    background-color: #e5e5e5;

    .village-info-top {
      .clear-fix-float();
      //margin-bottom: 20px;
      //height: 100px;
      min-height: 100px;
      height: auto;
      background-color: #ffffff;
      border-radius: 10px;

      .case-all-top-title {
        width: 72;
        height: 32;
        float: left;
        margin-top: 40px;
        margin-left: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 89% */

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.5);
      }
      .case-all-top-date {
        width: 226;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-group {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-item {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-confirm {
        width: 85;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .confirm-button {
          color: white;
          background: #4dbaa7;
          border-radius: 6px;
          &:focus {
            -webkit-appearance: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
      .case-all-top-export {
        width: 110px;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .export-button {
          &:focus {
            -webkit-appearance: none;
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.85);
            outline: none;
            box-shadow: none;
          }
        }
      }
      .case-all-top-back-dashboard {
        width: 110px;
        height: 40px;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .confirm-button {
          color: white;
          background: #4dbaa7;
          border-radius: 6px;
          &:focus {
            -webkit-appearance: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .village-info-detail {
      .clear-fix-float();
      margin-top: 20px;
      //border-radius: 25px;
      //background-color: cornflowerblue;
      width: 100%;
      //height: 200px;
      background: #ffffff;

      .switch-mode-control {
        margin-top: 18px;
        .mode-btn-shadow {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 2px;
        }
      }
    }
  }
</style>
